<template>
  <div class="tokyo_tm_about">
    <div class="about_image">
	<br><br><br><br><br><br><br>
      <!-- <img src="../assets/img/slider/2.jpg" alt="about" /> -->
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">
        Electrical and Computer Engineer, Software Developer, Project Manager and Researcher
      </h3>
      <div class="description_inner">
        <div class="left">
          <p>
            Hello 👋,<br />
            I am a creative engineer with experience in research. I have
            developed software, mobile applications and solutions for hardware
            integration. I am committed person that learns fast, adapts quickly
            and likes to work in team. I am always seeking for opportunities to
            be in innovative and challenging projects that allow me to improve
            myself.
          </p>
          
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Age:</span>30</p>
            </li>
            <li>
              <p><span>Location:</span>Lisbon, Portugal</p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a href="mailto:mail@gmail.com">me@fabioseixaslopes.com</a>
              </p>
            </li>
            <li>
              <p>
                <span>CV:</span>
                <a href="cv.pdf">Download</a>
              </p>
            </li>
            <li>
              <p><span>Available:</span>January 2024, Remote.</p>
            </li>
            <li>
              <p><br></p>
            </li>
            <li>
                <div class="tokyo_tm_button">
                  <button class="ib-button" @click="showModal">
                    Click To See More Info
                  </button>
                </div>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Technical Skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Java (Android)</span
                        ><span class="number">Good</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 80%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">Python</span
                        ><span class="number">Good</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 80%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
					<div class="progress_inner">
                      <span
                        ><span class="label">JavaScript, HTML, CSS</span
                        ><span class="number">Reasonable</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 70%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">C/C++</span
                        ><span class="number">Reasonable</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 60%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">SQL and Databases</span
                        ><span class="number">Reasonable</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 55%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                  </div>
                  <!-- End .tokyo_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Portuguese</span
                        ><span class="number">Native</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 100%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">English</span
                        ><span class="number">Fluent</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Spanish</span
                        ><span class="number">Elementary</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 60%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">French</span
                        ><span class="number">Elementary</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 50%"></div>
                        </div>
                      </div>
                    </div>
					<div class="progress_inner">
                      <span
                        ><span class="label">German</span
                        ><span class="number">Elementary</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 40%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Achievements</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>4</h3>
                      <span>Large European Projects</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>6+</h3>
                      <span>Years of Experience</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>50k+</h3>
                      <span>Lines of Code</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
              <div class="partners">
                <div class="about_title">
                  <h3>Projects and Entities</h3>
                </div>
                <Brand />
              </div>
              <!-- End .partner -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
import Brand from "./Brand";
export default {
  components: {
    Brand,
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
