<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Contact</span>
          <h3>Let's talk!</h3>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <a href="mailto: me@fabioseixaslopes.com">
      <div class="tokyo_tm_button">
        <button type="submit" class="ib-button">Send a Message</button>
      </div></a
    >

    <div>
      <p><br /><br /><br /><br /><br /><br /><br /><br /></p>
    </div>

    <div class="map_wrap">
      <iframe
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38963.88690470557!2d-9.164712849368483!3d38.721433236267046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19331a61e4f33b%3A0x400ebbde49036d0!2sLisboa!5e0!3m2!1spt-PT!2spt!4v1655311623962!5m2!1spt-PT!2spt"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {
  components: {},
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log(this.formData);
    },
  },
};
</script>
