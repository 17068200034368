<template>
  <div class="tokyo_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">Fábio A. Seixas-Lopes</h3>
        <p class="job">
          Electrical and Computer Engineer doing a PhD. and working as a
          Software Developer, Project Manager and Researcher.<br>Based in Lisbon.
        </p>

        <Social />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
import Social from "./Social";
export default {
  components: {
    Social,
  },
  data() {
    return {
      src1: require("../assets/img/slider/4.jpg"),
    };
  },
};
</script>

<style lang="scss"></style>
