<template>
  <div class="tokyo_tm_news">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>News</span>
          <h3>Latest News</h3>
        </div>
      </div>
    </div>
    <ul class="tokyo_tm_news_inner">
      <li>
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + popup8 + ')' }"
              @click="showModal8"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>30 June 2023</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal8">
              TeleRehaB DSS Project Meeting at Caparica, Portugal
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal6">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>
        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive8 }"
            id="modal"
            @click="closeModal8"
            v-if="isVisible8"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal8">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup8 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a>
                        <span>30 June 2023</span>
                      </p>
                    </div>
                    <h3 class="title">
                      TeleRehaB DSS Project Meeting at Caparica, Portugal
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Project meeting to discuss project developments
                      </p>
                      <p>
                        A meeting to discuss the developments of the project, share ideas and plan the upcoming work. With the participation of every partner, it was fruitful event with many discussions about technical and clinical issues.
                        There was also an opportunity to enjoy the sun in Caparica, with a friendly game of volleyball and a few drinks afterwards.
                      </p>
                      <center>
                        <img src="../assets/img/news/6-2.jpg"/>
                      </center>
                    </div>
                    <!-- END DESCRIPTION -->

                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
      <li>
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + popup7 + ')' }"
              @click="showModal7"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>14 December 2022</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal7">
              TeleRehab DSS Kick-Off at Atenas, Greece
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal6">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>
        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive7 }"
            id="modal"
            @click="closeModal7"
            v-if="isVisible7"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal7">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup7 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">TeleRehab DSS Kick-Off at Atenas, Greece</a>
                        <span>14 December 2022</span>
                      </p>
                    </div>
                    <h3 class="title">
                      TeleRehab DSS Kick-Off at Atenas, Greece
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Project Kick-off Meeting
                      </p>
                      <p>
                        A great opportunity to meet the people involved in the project and discuss some initial ideas. With presentations about the partners, the tasks and plans, it was a successful meeting with a lot of interesting discussions.
                      </p>
                      <center>
                        <img src="../assets/img/news/5-2.jpg"/>
                      </center>
                    </div>
                    <!-- END DESCRIPTION -->

                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
      <li>
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + popup6 + ')' }"
              @click="showModal6"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>03 July 2022</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal6">
              Smart4Health event at ICIE
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal6">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>
        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive6 }"
            id="modal"
            @click="closeModal6"
            v-if="isVisible6"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal6">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup6 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a>
                        <span>03 July 2022</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Smart4Health event at ICIE
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Smart4Health presentation at the International Conference of Innovation in Engineering
                      </p>
                      <p>
                        Dissemination of the Smart4Health project with demonstration o the platform and the mobile application features with 
                        health and wellbeing devices and sensors. Presentation of project objectives and architecure, from a technical perspective.
                        Discussion with conference participants about digital health solutions and innovation in health.
                      </p>
                      <center>
                        <img src="../assets/img/news/guima1.jpeg"/><img src="../assets/img/news/guima2.jpeg"/><img src="../assets/img/news/guima3.jpeg"/>
                      </center>
                    </div>
                    <!-- END DESCRIPTION -->

                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
      <li>
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + popup5 + ')' }"
              @click="showModal5"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>19 June 2022</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal5">
              Running the Marginal à Noite!
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal5">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>
        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive5 }"
            id="modal"
            @click="closeModal5"
            v-if="isVisible5"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal5">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup5 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a>
                        <span>19 June 2022</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Running the Marginal à Noite!
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Great race in Oeiras, probably the must fun I did in a while!
                      </p>
                      <p>
                        Very enjoyable race with a cool breeze from the river
                        and the lower temperatures that night. The race course helped a lot,
                        it was pretty flat and always next to the water. At some places it
                        was a bit too dark, I slowed a lot to avoid tripping on anyone, and
                        there were a lot of people at the race, 
                        but it had a pretty enjoyable view of running without lights nearby
                        and seeing the other margin of the river.
                        My time was okay ~39:31 (8K), I did not pushed myself too much, still recoverying
                        from COVID and the long training pause, but I did not feel tired, so
                        it's a good thing.
                      </p>
                      <center>
                    <video width="200" height="350" autoplay controls>
                      <source src="../assets/img/news/1.mp4">
                       Your browser does not support the video tag.
                    </video>
                    <video width="200" height="350" autoplay controls>
                      <source src="../assets/img/news/2.mp4" type="video/mp4">
                       Your browser does not support the video tag.
                    </video>
                    <br><br>
                        <a target="_blank" rel="noopener noreferrer"  href="
                        https://www.strava.com/activities/7331032206">Check my activity on Strava</a>
                    </center>
                    </div>
                    <!-- END DESCRIPTION -->

                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
      <li>
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + popup1 + ')' }"
              @click="showModal"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>08 May 2022</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal">
              Running the Lisbon Half-Marathon: Almada to Belém
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>
        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive }"
            id="modal"
            @click="closeModal"
            v-if="isVisible"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup1 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a>
                        <span>08 May 2022</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Running the Lisbon Half-Marathon from Almada to Belém
                      hosting
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Running is a great way to clear your mind and exercise
                        your body!
                      </p>
                      <p>
                        It was a very hot day (over 30ºC) for running a
                        half-marathon, nevertheless if it's something I wanted
                        to do, it's something it needs to be done. The training
                        in the previous weeks was not plenty and I just had
                        COVID for the second time in six months.
                      </p>
                      <div class="quotebox">
                        <div class="icon">
                          <img
                            class="svg"
                            src="../assets/img/svg/quote.svg"
                            alt="tumb"
                          />
                        </div>
                        <p>
                          I was happy to finish the race, considering all the
                          adversities.
                        </p>
                      </div>
                      <!-- END QUOTEBOX -->
                      <p>
                        It was a rough start, a lot of people running in the
                        narrow road of the 25 de Abril bridge. I had a good pace
                        for a few kilometers, but I decided to manage my effort,
                        so around the 10K mark I lowered my pace due to the
                        extreme heat and fatigue. Saw a lot of people stopping,
                        quitting and even a few fainting. I'm not sorrow because
                        the last kilometers were a pain. In the end I did ~2
                        hours, 15 minutes more than my worst time in a
                        half-marathon race. Altough it was my worst performance
                        ever in this distance, I was happy to manage to finish
                        the race, considering all the adversities.
                      </p>
                      <iframe
                        src="https://www.instagram.com/p/CdZN1U4IFgI/embed"
                        width="300"
                        height="600"
                        style="text-align: center"
                      ></iframe>
                    </div>
                    <!-- END DESCRIPTION -->

                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->

      <li>
        <div class="list_inner">
          <div class="image" @click="showModal2">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + src2 + ')' }"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>31 March 2022</span>
              </p>
            </div>
            <!-- End meta info -->
            <h3 class="title" @click="showModal2">
              Setting up the Smart4Health stand in Funchal, Madeira
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal2">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>

        <!-- Start second modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive2 }"
            id="modal"
            @click="closeModal2"
            v-if="isVisible2"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal2">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup2 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a
                        ><span>31 March 2022</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Setting up the Smart4Health stand in Funchal, Madeira
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        A stand to showcase the Smart4Health project.
                      </p>
                      <p>
                        It is always good to visit the beautiful Madeira. This
                        time, the objective of the trip was to set up the stand
                        for the Digital Health and Wellbeing events to take
                        place in Funchal. In these events, the objective was to
                        present the health projects included in this initiative,
                        showcase how they work and how can people benefit from
                        them and also provide lumbar physiotherapy training and
                        health assessments (blood pressure, heart rate, etc.).
                      </p>
                    </div>
                    <!-- END DESCRIPTION -->
                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Second Modal -->
      </li>
      <!-- End single blog -->

      <li>
        <div class="list_inner">
          <div class="image" @click="showModal3">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + src3 + ')' }"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>12 Nov 2021</span>
              </p>
            </div>
            <h3 class="title" @click="showModal3">
              Lecture about hardware integration and communication protocols
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal3">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>

        <!-- Start third modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive3 }"
            id="modal"
            @click="closeModal3"
            v-if="isVisible3"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal3">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup3 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a
                        ><span>15 Feb 2021</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Lecture about hardware integration and communication
                      protocols
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Showcasing the work developed for the Smart4Health
                        project.
                      </p>
                      <p>
                        A very fun opportunity to show how the Citizen Hub app
                        was developed, regarding the integration of various
                        sensors and devices, through communication protocol
                        adapters and reverse-engineering. The class was a
                        lecture included in the subject of "Architecture for the
                        Integration of Systems" of the final year of the
                        Master's Degree for electrical and computer engineering
                        in FCT-NOVA.
                      </p>
                      <p>
                        The Citizen Hub application can be downloaded in:
                        <a
                          href="https://play.google.com/store/search?q=citizen+hub&c=apps"
                          >Link to Google Play Store</a
                        >
                      </p>
                    </div>
                    <!-- END DESCRIPTION -->
                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End third modal -->
      </li>
      <!-- End single blog -->

      <li>
        <div class="list_inner">
          <div class="image" @click="showModal4">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: 'url(' + src4 + ')' }"
            ></div>
          </div>
          <div class="details">
            <div class="extra">
              <p class="date">
                By <a href="#">Fábio</a><span>01 Jan 2021</span>
              </p>
            </div>
            <h3 class="title" @click="showModal4">
              Smart4Health project presentation in Funchal, Madeira
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal4">
              <a><span>Read More</span></a>
            </div>
          </div>
        </div>

        <!-- START FOURTH MODAL -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive4 }"
            id="modal"
            @click="closeModal4"
            v-if="isVisible4"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal4">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>
                  <!-- END CLOSE ICON -->
                  <div class="image">
                    <img src="../assets/img/thumbs/4-3.jpg" alt="tumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + popup4 + ')' }"
                    ></div>
                  </div>
                  <!-- END IMAGE-->
                  <div class="details">
                    <div class="extra">
                      <p class="date">
                        By <a href="#">Fábio</a
                        ><span>22 Jan 2021</span>
                      </p>
                    </div>
                    <h3 class="title">
                      Smart4Health project presentation in Funchal, Madeira
                    </h3>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content">
                    <div class="descriptions">
                      <p class="bigger">
                        Presentation of the Smart4Health project in Funchal.
                      </p>
                      <iframe
                        src="https://www.instagram.com/p/BxcmXiMhjz6/embed"
                        width="300"
                        height="600"
                        style="text-align: center"
                      ></iframe>
                    </div>
                    <!-- END DESCRIPTION -->
                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- END SECOND MODAL -->
      </li>
      <!-- End single blog -->
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      isActive6: false,
      isActive7: false,
      isActive8: false,
      src1: require("../assets/img/news/1.jpg"),
      src2: require("../assets/img/news/2.jpg"),
      src3: require("../assets/img/news/3.jpg"),
      src4: require("../assets/img/news/4.jpg"),
      src5: require("../assets/img/news/marginal.jpeg"),
      src6: require("../assets/img/news/guimaraes.jpeg"),
      src7: require("../assets/img/news/5-1.jpg"),
      src8: require("../assets/img/news/6-1.jpg"),
      popup1: require("../assets/img/news/1.jpg"),
      popup2: require("../assets/img/news/2.jpg"),
      popup3: require("../assets/img/news/3.jpg"),
      popup4: require("../assets/img/news/4.jpg"),
      popup5: require("../assets/img/news/marginal.jpeg"),
      popup6: require("../assets/img/news/guimaraes.jpeg"),
      popup7: require("../assets/img/news/5-1.jpg"),
      popup8: require("../assets/img/news/6-1.jpg"),
      isVisible: false,
      isVisible2: false,
      isVisible3: false,
      isVisible4: false,
      isVisible5: false,
      isVisible6: false,
      isVisible7: false,
      isVisible8: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    showModal2: function () {
      this.isActive2 = true;
      this.isVisible2 = true;
    },
    showModal3: function () {
      this.isActive3 = true;
      this.isVisible3 = true;
    },
    showModal4: function () {
      this.isActive4 = true;
      this.isVisible4 = true;
    },
    showModal5: function () {
      this.isActive5 = true;
      this.isVisible5 = true;
    },
    showModal6: function () {
      this.isActive6 = true;
      this.isVisible6 = true;
    },
    showModal7: function () {
      this.isActive7 = true;
      this.isVisible7 = true;
    },
    showModal8: function () {
      this.isActive8 = true;
      this.isVisible8 = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
    closeModal2: function () {
      this.isActive2 = false;
      this.isVisible2 = false;
    },
    closeModal3: function () {
      this.isActive3 = false;
      this.isVisible3 = false;
    },
    closeModal4: function () {
      this.isActive4 = false;
      this.isVisible4 = false;
    },
    closeModal5: function () {
      this.isActive5 = false;
      this.isVisible5 = false;
    },
    closeModal6: function () {
      this.isActive6 = false;
      this.isVisible6 = false;
    },
    closeModal7: function () {
      this.isActive7 = false;
      this.isVisible7 = false;
    },
    closeModal8: function () {
      this.isActive8 = false;
      this.isVisible8 = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
